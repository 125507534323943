#menu ul {
    display: flex;
    list-style-type: none;
    padding: 0;
}

#menu a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1rem;
}

.sub-menu {
    /*padding-right: 20px;*/
}

#menu {
    font-weight: 600;
    /*font-size: 18px;*/
    /*border-radius: 10px;*/
    width: 100%;
    text-transform: uppercase;
}

#menu ul {
    margin: 0;
    padding: 0;
    position: relative;
}

#menu > ul,
#menu > ul > li,
#menu > ul > li > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

#menu > ul > li {
    //width: 25%;
    padding: 0 10px;
}

#menu > ul > li:first-child {
    padding-left: 0;
}

#menu > ul > li:last-child {
    padding-right: 0;
}

#menu > ul > li > a {
    width: 100%;
}

#menu > ul > li.catalog-menu {
}

#menu ul {
    text-align: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

#menu > ul > li > a {
    border-radius: 10px;
    /*min-height: 60px;*/
    /*text-decoration: none;*/
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

#menu ul li a:hover {
    color: rgba(0, 0, 0, 0.9);
}

#menu > ul > .sub-menu a {
    /*color: #fff;*/
    /*background: #bbd975;*/
    /*padding: 10px 30px;*/
}

/*sub menu*/
#menu .sub-menu a {
    /*color: #c67be4;*/
    /*background: #fff;*/
    /*padding: 10px 30px;*/
}

#menu .sub-menu a:hover {
    /*background: #a7bd79;*/
    /*color: #fff;*/
}

#menu ul li,
#menu ul li.open {
    position: relative;
    cursor: default;
}

#menu ul ul,
#menu ul li ul li,
#menu ul li ul a {
    display: block;
}

#menu ul ul {
    background: #fff;
    text-align: left;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    /*width: 302px;*/
    /*width: 100%;*/
}

#menu ul ul li {
    position: relative;
}

/*#menu ul ul li.has-sub > a:after {*/
/*    font-family: "Font Awesome 5";*/
/*    content: "\f105";*/
/*    position: absolute;*/
/*    right: 15px;*/
/*}*/

#menu ul li ul li a {
    padding: 5px 10px;
    /*text-decoration: none;*/
    /*color: #fff;*/
    /*background: #fff;*/
    /*font-size: 16px;*/
}

#menu ul ul ul {
    top: 0;
    left: auto;
    right: -100%;
}
