// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');
// Font Awesome
//@import "node_modules/font-awesome/scss/font-awesome.scss";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// highlight.js
@import "~highlight.js/styles/idea.css";
@import "hljs";

// menu
@import "menu";
